import axios from 'axios';
import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { message } from 'antd';
import { ultrabookAuth } from '@leyan/ultrabook-sdk';
import { superBookTokenCache } from '../../services/cache';

const SUPER_BOOK_AUTH_ENDPOINT = process.env.REACT_APP_SUPER_BOOK_AUTH_ENDPOINT;
const SUPER_BOOK_LOGIN = process.env.REACT_APP_SUPER_BOOK_LOGIN;

const cacheKey = `@${process.env.REACT_APP_NAME}/token`;

function authorize(token: string) {
  localStorage.setItem(cacheKey, token);
}

function superBookAuthorize(token: string) {
  superBookTokenCache.set(token)
}
function unauthorize() {
  localStorage.removeItem(cacheKey);
}

interface Props {
  children: React.ReactElement;
}

function SuperBookAuthorization(props: Props) {
  const { children } = props;

  const [, platform, sid] = window.location.pathname.split('/');
  const { access_token: superBookToken } = qs.parse(window.location.href.split('?')[1]);
  const [loading, setLoading] = useState<boolean>(!!superBookToken && !!platform && !!sid);

  useEffect(() => {
    if (loading) {
      message.loading({
        content: '授权中...',
        duration: 0,
      });
    } else {
      message.destroy();
    }
  }, [loading]);

  const { watchTokenExpired, stopWatchTokenExpired } = ultrabookAuth({
    // 可选，默认为 window.location.origin
    subAppOrigin: window.location.origin,
    // 子应用域下token更新
    onRenewToken: (newToken) => {
      authorize(newToken);
    },
    // 错误
    onError: ({ data }) => {
      // eslint-disable-next-line no-console
      // console.log(data);
      // if (data === 错误码 ) {
      // }
    },
  });

  const onFail = () => {
    message.error('授权失败');
    unauthorize();
    window.location.href = SUPER_BOOK_LOGIN as string;
  };

  useEffect(() => {
    if (platform && sid && superBookToken) {
      superBookAuthorize(String(superBookToken));
      axios
        .get(`${SUPER_BOOK_AUTH_ENDPOINT}/exchange_token?seller_id=${sid}`, {
          headers: {
            authorization: `Bearer ${superBookToken}`,
          },
        })
        .then((res) => {
          authorize(res.data?.access_token);
          watchTokenExpired();
        })
        .catch((err) => {
          onFail();
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return stopWatchTokenExpired;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, sid, superBookToken]);

  return loading ? null : children;
}

export default SuperBookAuthorization;
