import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { RouterConfig, PermissionedRoute } from '../../types';
import Permission from '../Permission';

/**
 * 重定向渲染函数
 * @param path 重定向路径
 */
function renderRedirect(path: string) {
  return <Navigate key={`__redirect:${path}`} replace to={path} />;
}

/**
 * 路由渲染函数
 * @param routes 路由项配置
 */
function renderRoutes(routes: PermissionedRoute[] = [], parentRoute?: PermissionedRoute) {
  const parentRedirect = parentRoute?.redirect;
  const shouldRedirect = typeof parentRedirect === 'string' || Boolean(parentRedirect);
  let parentRedirectNode =
    typeof parentRedirect === 'string' ? renderRedirect(parentRedirect) : null;

  const nodes = routes.reduce<React.ReactNode[]>((nodes, route) => {
    const { path, caseSensitive, element, permission, children, helmet, menu } = route;

    const childrenNodes = children ? renderRoutes(children, route) : [];
    const hasChildrenNodes = childrenNodes.length > 0;

    if (element !== undefined || hasChildrenNodes) {
      if (shouldRedirect && !parentRedirectNode) {
        parentRedirectNode = renderRedirect(path);
      }

      nodes.push(
        <Route
          key={path}
          path={path}
          caseSensitive={caseSensitive}
          element={
            <Permission value={permission}>
              {helmet && (
                <Helmet
                  {...helmet}
                  title={helmet.title || (typeof menu?.label === 'string' ? menu.label : undefined)}
                />
              )}
              {element || <Outlet />}
            </Permission>
          }
        >
          {childrenNodes}
        </Route>,
      );
    }

    return nodes;
  }, []);

  if (parentRedirectNode) {
    nodes.push(parentRedirectNode);
  }

  return nodes;
}

/**
 * 路由渲染器属性
 */
export interface RouterRenderProps extends Omit<RouterConfig, 'history' | 'routes'> {
  routes?: PermissionedRoute[];
}

/**
 * 路由渲染器
 * @param props 路由渲染器属性
 */
function RouterRender(props: RouterRenderProps) {
  const { basename, routes } = props;

  const nodes = useMemo(() => {
    return renderRoutes(routes);
  }, [routes]);

  return <Routes basename={basename}>{nodes}</Routes>;
}

export default RouterRender;
