import React, { useContext, useMemo } from 'react';
import { useLocation, createRoutesFromArray, matchRoutes, useResolvedPath } from 'react-router-dom';

import { RouterConfig, MatchedRoute, RouteConfig, PermissionedRoute } from '../../types';
import refine, { Refinable } from '../../utils/refine';
import createNamedContext from '../../utils/createNamedContext';
import { useInitialState } from '../InitialState';

/**
 * 获取权限路由信息
 * @param initialState 初始状态
 * @param routes 路由配置项
 */
export function getPermissionedRoutes<T = unknown>(initialState: T, routes: RouteConfig[]) {
  return routes.reduce<PermissionedRoute[]>((result, route) => {
    const { access, children } = route;

    const permission =
      initialState === undefined || access === undefined ? 'write' : access(initialState);

    if (permission) {
      result.push({
        ...route,
        permission,
        children: children ? getPermissionedRoutes(initialState, children) : undefined,
      });
    }

    return result;
  }, []);
}

/**
 * 获取匹配路由信息
 * @param routes 路由项配置
 * @param pathname 当前路径
 * @param basename 基础路径
 */
export function getMatchedRoutes(routes: PermissionedRoute[], pathname: string, basename: string) {
  const matchedRoutes: MatchedRoute[] = [];
  const routeMatches = matchRoutes(createRoutesFromArray(routes), pathname, basename);

  if (routeMatches) {
    let currentRoutes = routes;

    for (const match of routeMatches) {
      const route = currentRoutes.find((route) => {
        return match.route.path === route.path;
      });

      if (!route) {
        break;
      }

      matchedRoutes.push({
        ...route,
        pathname: match.pathname.indexOf('/') === 0 ? match.pathname : `/${match.pathname}`,
        params: match.params,
      });

      if (!route.children) {
        break;
      }

      currentRoutes = route.children;
    }
  }

  return matchedRoutes;
}

/**
 * 路由元信息 `context` 值
 */
export interface RouterMetaContextValue<T = unknown>
  extends Required<Omit<RouterConfig, 'history'>> {
  /**
   * 当前路径
   */
  pathname: string;
  /**
   * 权限路由信息
   */
  permissionedRoutes: PermissionedRoute<T>[];
  /**
   * 匹配路由信息
   */
  matchedRoutes: MatchedRoute<T>[];
}

/**
 * 路由元信息 `context`
 */
export const RouterMetaContext = createNamedContext<RouterMetaContextValue>('RouterMetaContext', {
  pathname: '/',
  basename: '',
  routes: [],
  permissionedRoutes: [],
  matchedRoutes: [],
});

/**
 * 获取路由元信息
 */
export function useRouterMeta<T = unknown>() {
  const meta = useContext(RouterMetaContext);

  return meta as RouterMetaContextValue<T>;
}

/**
 * 获取当前路径
 */
export function usePathname() {
  const { pathname } = useRouterMeta();

  return pathname;
}

/**
 * 获取基础路径
 */
export function useBasename() {
  const { basename } = useRouterMeta();

  return basename;
}

/**
 * 获取路由项配置
 */
export function useRoutes<T = unknown>() {
  const { routes } = useRouterMeta<T>();

  return routes;
}

/**
 * 获取权限路由信息
 */
export function usePermissionedRoutes<T = unknown>() {
  const { permissionedRoutes } = useRouterMeta<T>();

  return permissionedRoutes;
}

/**
 * 获取匹配路由信息
 */
export function useMatchedRoutes<T = unknown>() {
  const { matchedRoutes } = useRouterMeta<T>();

  return matchedRoutes;
}

/**
 * 获取当前匹配路由信息
 */
export function useCurrentMatchedRoute<T = unknown>() {
  const { pathname } = useResolvedPath('');
  const matchedRoutes = useMatchedRoutes<T>();

  return matchedRoutes.find((route) => {
    return route.pathname === pathname;
  });
}

const { Provider: RouterMetaProvider } = RouterMetaContext;

/**
 * 路由元信息属性
 */
export interface RouterMetaProps<T = unknown> extends Omit<RouterConfig, 'history'> {
  /**
   * 子节点或子节点渲染函数
   */
  children?: Refinable<(meta: RouterMetaContextValue<T>) => React.ReactNode>;
}

/**
 * 路由元信息
 */
function RouterMeta<T = unknown>(props: RouterMetaProps<T>) {
  const { basename = '', routes, children } = props;

  const { pathname } = useLocation();
  const initialState = useInitialState();
  const parentRouterMetaContextValue = useRouterMeta();
  const permissionedRoutes = useMemo(() => {
    return routes ? getPermissionedRoutes(initialState, routes) : undefined;
  }, [initialState, routes]);
  const routerMetaContextValue = useMemo(() => {
    if (routes && permissionedRoutes) {
      const matchedRoutes = getMatchedRoutes(permissionedRoutes, pathname, basename);

      return {
        basename,
        pathname,
        routes,
        permissionedRoutes,
        matchedRoutes,
      };
    }

    return {
      ...parentRouterMetaContextValue,
      basename,
      pathname,
    };
  }, [basename, parentRouterMetaContextValue, pathname, permissionedRoutes, routes]);

  return (
    <RouterMetaProvider value={routerMetaContextValue}>
      {refine(children, routerMetaContextValue)}
    </RouterMetaProvider>
  );
}

export default RouterMeta;
