import React from 'react';
import {
  BrowserRouter,
  HashRouter,
  MemoryRouter,
  BrowserRouterProps,
  HashRouterProps,
} from 'react-router-dom';

import { RouterConfig } from '../../types';

type MemoryRouterProps = React.ComponentProps<typeof MemoryRouter>;

/**
 * `history` 路由映射
 */
const ROUTER_MAP = {
  browser: BrowserRouter,
  hash: HashRouter,
  memory: MemoryRouter,
} as const;

/**
 * 路由属性
 */
export interface RouterProps
  extends Pick<RouterConfig, 'history'>,
    Omit<BrowserRouterProps & HashRouterProps & MemoryRouterProps, 'children'> {
  children?: React.ReactNode;
}

/**
 * 路由
 * @param props 路由属性
 */
function Router(props: RouterProps) {
  const { history = 'hash', window, initialEntries, initialIndex, children } = props;

  const { [history]: Router } = ROUTER_MAP;
  const routerProps = history === 'memory' ? { initialEntries, initialIndex } : { window };

  return <Router {...routerProps}>{children}</Router>;
}

export default Router;
