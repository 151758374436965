import { Suspense } from 'react';
import locale from 'antd/es/locale/zh_CN';
import { ThemeProvider } from '@emotion/react/macro';
import { Result, Button, ConfigProvider } from 'antd';

import routerConfig from './config/router';
import logger from './config/logger';
import ErrorBoundary from './components/ErrorBoundary';
import AppLoading from './components/AppLoading';
import Router from './components/Router';
import RouterRender from './components/RouterRender';
import Authorization from './components/Authorization';
import authorization from './config/authorization';
import InitialState from './components/InitialState';
import RouterMeta from './components/RouterMeta';
import SuperBookAuthorization from './components/SuperBookAuthorization';

const {
  ConfigContext: { Consumer: ConfigConsumer },
} = ConfigProvider;

/**
 * 应用入口
 */
function App() {
  return (
    <ErrorBoundary
      fallback={(error) => {
        logger.error('应用加载失败: %o', error);

        return (
          <Result
            status="500"
            title="应用加载失败"
            subTitle="请稍后尝试手动刷新浏览器重新加载"
            extra={
              <Button type="primary" onClick={() => window.location.reload()}>
                立即刷新
              </Button>
            }
          />
        );
      }}
    >
      <Suspense fallback={<AppLoading />}>
        <ConfigProvider locale={locale}>
          <ConfigConsumer>
            {({ getPrefixCls }) => {
              return (
                <ThemeProvider theme={{ getAntdPrefixedClassName: getPrefixCls }}>
                  <SuperBookAuthorization>
                    <Router {...routerConfig}>
                      <Authorization
                        storage={authorization.storage}
                        cacheKey={authorization.cacheKey}
                        queryKey={authorization.queryKey}
                        fallback={authorization.fallback}
                        onAuthorize={authorization.onAuthorize}
                        onUnauthorize={authorization.onUnauthorize}
                      >
                        {({ authorized }) => {
                          return (
                            <InitialState
                              skip={!authorized}
                              getInitialState={authorization.getInitialState}
                            >
                              <RouterMeta
                                basename={routerConfig.basename}
                                routes={routerConfig.routes}
                              >
                                {({ basename, permissionedRoutes }) => {
                                  return (
                                    <RouterRender basename={basename} routes={permissionedRoutes} />
                                  );
                                }}
                              </RouterMeta>
                            </InitialState>
                          );
                        }}
                      </Authorization>
                    </Router>
                  </SuperBookAuthorization>
                </ThemeProvider>
              );
            }}
          </ConfigConsumer>
        </ConfigProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
