import React, { useContext, useMemo } from 'react';

import { RoutePermission } from '../../types';
import createNamedContext from '../../utils/createNamedContext';
import refine, { Refinable } from '../../utils/refine';

/**
 * 权限 `context`
 */
const PermissionContext = createNamedContext<RoutePermission>('Permission', 'write');

/**
 * 获取当前权限
 */
export function usePermission() {
  return useContext(PermissionContext);
}

const { Provider: PermissionProvider } = PermissionContext;

/**
 * 权限容器属性
 */
export interface PermissionProps {
  /**
   * 当前权限
   */
  value?: RoutePermission;
  /**
   * 子元素
   */
  children?: Refinable<(permission: RoutePermission) => React.ReactNode>;
}

/**
 * 权限容器
 * @param props 权限容器属性
 */
function Permission(props: PermissionProps) {
  const { value, children } = props;

  const parentPermission = usePermission();

  const permissionValue = useMemo(() => {
    return value || parentPermission;
  }, [parentPermission, value]);

  return (
    <PermissionProvider value={permissionValue}>
      {refine(children, permissionValue)}
    </PermissionProvider>
  );
}

export default Permission;
