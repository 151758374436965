import { PartialPath as HistoryPartialPath, State } from 'history';
import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import {
  useHref as useRouterHref,
  useLocation,
  useNavigate as useRouterNavigate,
} from 'react-router-dom';

export type Query = Record<string, any>;

/**
 * 路径对象
 */
export interface Path extends Omit<HistoryPartialPath, 'search'> {
  query?: Query;
}

/**
 * 路径
 */
export type To = string | Path;

/**
 * 统一 `search` 格式
 * @param search `search`
 */
export function normalizeSearch(search: string) {
  let nextSearch = search.trim();

  if (nextSearch && !/^\?/.test(nextSearch)) {
    nextSearch = `?${nextSearch}`;
  }

  return nextSearch;
}

/**
 * 简单的 `query` 解析函数
 * @param search `search`
 */
export function simpleParse(search: string) {
  return parse(search, {
    parseBooleans: true,
    arrayFormat: 'index',
  });
}

/**
 * 简单的 `query` 序列化函数
 * @param query `query`
 */
export function simpleStringify(query: Query) {
  return stringify(query, {
    arrayFormat: 'index',
  });
}

/**
 * 创建 `Query` hooks 选项
 */
export interface CreateQueryHooksOptions {
  /**
   * 解析 `search`
   * @param search `search`
   */
  parse?(search: string): Query;
  /**
   * 序列化 `query`
   * @param query `query`
   */
  stringify?(query: Query): string;
}

/**
 * 创建 `Query` hooks
 * @param options 创建 `Query` hooks 选项
 */
function createQueryHooks(options: CreateQueryHooksOptions = {}) {
  const { parse = simpleParse, stringify = simpleStringify } = options;

  /**
   * 创建 `search`
   * @param query `query`
   */
  function createSearch(query: Query) {
    return normalizeSearch(stringify(query));
  }

  /**
   * 创建 `search` hook
   */
  function useCreateSearch() {
    return createSearch;
  }

  /**
   * `query` hook
   */
  function useQuery() {
    const { search } = useLocation();
    const query = useMemo(() => {
      return parse(search);
    }, [search]);

    return query;
  }

  /**
   * `navigate` hook
   */
  function useNavigate() {
    const routerNavigate = useRouterNavigate();

    const navigate = useCallback(
      (to: To, options?: { replace?: boolean; state?: State }) => {
        return routerNavigate(to, options);
      },
      [routerNavigate],
    );

    return navigate;
  }

  /**
   * `href` hook
   * @param to 路径
   */
  function useHref(to: To) {
    const nextTo = useMemo(() => {
      if (typeof to === 'string') {
        return to;
      }

      const { query, ...otherTo } = to;

      return {
        ...otherTo,
        ...(query
          ? {
              search: createSearch(query),
            }
          : {}),
      };
    }, [to]);

    const href = useRouterHref(nextTo);

    return href;
  }

  return { useCreateSearch, useQuery, useNavigate, useHref };
}

const { useCreateSearch, useQuery, useNavigate, useHref } = createQueryHooks();

export { useCreateSearch, useNavigate, useHref };

export default useQuery;
