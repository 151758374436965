import { lazy } from 'react';
import { HomeOutlined, SettingOutlined } from '@ant-design/icons';

import { RouterConfig } from '../types';


/**
 * 404页面
 */
const NotFound = lazy(() => import('../views/NotFound'));
/**
 * 403页面
 */
const Forbidden = lazy(() => import('../views/Forbidden'));
/**
* 主页面
*/
const Main = lazy(() => import('../views/Main'));
/**
 * 智能跟单
 */
const Marketing = lazy(() => import('../views/Marketing'));
/**
 * 营销设置
 */
const MarketingSetting = lazy(() => import('../views/MarketingSetting'));
/**
 * 全局搜索
 */
const GlobalSearch = lazy(() => import('../views/GlobalSearch'));


/**
 * 路由配置
 */
const routerConfig: RouterConfig = {
  history: 'hash',
  routes: [
    {
      path: '/403',
      element: <Forbidden />,
      helmet: {
        title: '403',
      },
    },
    {
      path: '/',
      element: <Main />,
      redirect: true,
      helmet: {
        defaultTitle: '智能营销',
        titleTemplate: '%s - 智能营销',
      },
      children: [
        {
          path: 'marketing',
          helmet: {},
          element: <Marketing />,
          menu: {
            key: 'marketing',
            label: '智能跟单',
            link: '/marketing',
            icon: <HomeOutlined />,
          },
        },
        {
          path: 'marketingSetting',
          helmet: {},
          element: <MarketingSetting />,
          menu: {
            key: 'marketingSetting',
            label: '营销设置',
            link: '/marketingSetting',
            icon: <SettingOutlined />,
          },
        },
        {
          path: 'globalSearch',
          helmet: {},
          element: <GlobalSearch />,
          menu: {
            key: 'globalSearch',
            label: '全局搜索',
            enable: false,
          },
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
      helmet: {
        title: '404',
      },
    },
  ],
};

export default routerConfig;
